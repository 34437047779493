import app from '../images/help/app.png';
import clients from '../images/help/clients.png';
import internet_services from '../images/help/internet-services.png';
import max from '../images/help/max.svg';
import net from '../images/help/net.png';
import packages from '../images/help/packages.png';
import tv from '../images/help/tv.png';

export default {
  categories: [
    {
      fadedTitle: 'Натройки на',
      title: 'Телевизията',
      icon: tv,
      category: 'tv'
    },
    {
      fadedTitle: 'Натройки на',
      title: 'Интернета',
      icon: net,
      category: 'internet'
    },
    {
      fadedTitle: 'Въпроси по',
      title: 'Пакетните услуги',
      icon: packages,
      category: 'packages'
    },
    {
      fadedTitle: 'Въпроси по',
      title: 'Интернет услуги',
      icon: internet_services,
      category: 'internet-services'
    },
    {
      fadedTitle: 'Обслужване на',
      title: 'Клиенти',
      icon: clients,
      category: 'clients'
    },
    {
      fadedTitle: 'Въпроси по',
      title: 'Мобилното приложение',
      icon: app,
      category: 'app'
    },
    {
      fadedTitle: 'Въпроси по',
      title: 'MAX',
      icon: max,
      category: 'hbo-max'
    },
  ]
}
