import { BgWrapper } from '../components/shared/components';
import { CategoriesSection } from '../components/help/CategoriesSection';
import Layout from '../components/layout';
import { MostPopularSection } from '../components/help/MostPopularSection';
import React from 'react';
import SEO from '../components/seo';
import { SearchBox } from '../components/help/SearchBox';
import SectionTitles from '../components/shared/section-titles';
import { graphql } from 'gatsby';
import metaDescriptions from '../constants/meta-descriptions';

export default ({ data }) => {
	return (
		<BgWrapper bg={data.bg.childImageSharp.fluid.src} position="top">
			<Layout activeTab="/help-center/">
				<SEO
					title={metaDescriptions.faq.title}
					description={metaDescriptions.faq.metaDescription}
				/>
				<div className="col-12">
					<div style={{ paddingTop: '16rem' }}>
						<SectionTitles>
							<h1>
								<span className="d-none d-md-inline">
									Екипът на NetSurf е винаги на
									<br />
									линия да ти помогне
								</span>
								<span className="d-inline d-md-none">
									Екипът на NetSurf е винаги на линия да ти помогне
								</span>
							</h1>
							<hr className="center" />
						</SectionTitles>
					</div>

					<SearchBox />

					<MostPopularSection />

					<CategoriesSection posts={data.categoriesPosts} />
				</div>
			</Layout>
		</BgWrapper>
	);
};

export const query = graphql`
	query HelpCenterAll {
		categoriesPosts: allWordpressWpHelp {
			distinct(field: acf___category)
			edges {
				node {
					title
					slug
					acf {
						category
					}
				}
			}
		}
		bg: file(relativePath: { eq: "help/help-all-bg.png" }) {
			childImageSharp {
				fluid(maxWidth: 2000) {
					src
				}
			}
		}
	}
`;

