import * as JsSearch from "js-search"

import { Link, graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useReducer } from 'react';

import { GreyBox } from '../shared/components';

const initialState = {
  searchText: '',
  postBundle: null,
  search: null,
  results: null,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'field':
      return {
        ...state,
        [action.field]: action.value
      }
    default:
      return state;
  }
}


export const SearchBox = () => {
  const data = useStaticQuery(graphql`
    query SearchBox {
      allPosts: allWordpressWpHelp {
        edges {
          node {
            id
            title
            slug
            content
            acf {
              category
            }
          }
        }
      }
    }
  `);

  const [state, dispatch] = useReducer(reducer, initialState);
  const { searchText, results, search } = state;


  useEffect(() => {
    dispatch({
      type: 'field',
      field: 'postBundle',
      value: data.allPosts.edges,
    });
    initializeSearch();
  }, []);


  const initializeSearch = () => {
    const dataToSearch = new JsSearch.Search("id");
    const trimmedData = data.allPosts.edges.map(({ node }) => { return node; })
    dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy();
    dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer();

    dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex("id");
    dataToSearch.addIndex(['title']);
    dataToSearch.addIndex(['content']);
    dataToSearch.addDocuments(trimmedData);

    dispatch({
      type: 'field',
      field: 'search',
      value: dataToSearch,
    })
  }

  const inputHandler = e => {
    const queryResult = search.search(e.target.value);

    dispatch({
      type: 'field',
      field: 'searchText',
      value: e.currentTarget.value
    });
    dispatch({
      type: 'field',
      field: 'results',
      value: queryResult
    });
  }

  return (
    <div className='container my-4'>
      <GreyBox>
        <form className='popup-form'>
          <div className="form-row">
            <div className="form-group input-group col-12 m-0 p-0">
              <div className="input-group-prepend">
                <div className="input-group-text"><span className='icon search'></span></div>
              </div>
              <input
                type="text"
                className="form-control search-bar"
                id="search_text"
                placeholder="Търсете всичко, което ви интересува"
                value={searchText}
                onFocus={(e) => e.target.placeholder = ""}
                onBlur={(e) => e.target.placeholder = "Търсете всичко, което ви интересува"}
                onChange={inputHandler}
              />
            </div>
          </div>
        </form>
        {results && (
          <ul className='category-cards mt-3'>
            {results.map((node, index) => (
              <li key={index} className='my-2'>
                <Link to={`/help-center/${node.acf.category}/${node.slug}/`}><span className='icon category-arrow'></span>{node.title}</Link>
                <hr className='normal-bottom' />
              </li>
            ))}
          </ul>
        )}

      </GreyBox>
    </div>
  );
};
