import { CategoryCardWrapper } from '../shared/components';
import { Link } from 'gatsby';
import React from 'react';
import constants from '../../constants/help';

export const CategoriesSection = ({ posts }) => {
	const last = posts.edges.pop();
	posts.edges.unshift(last);
	const CategoryCard = ({ fadedTitle, title, icon, post }) =>
		!post ? null : (
			<div className="m-3 text-center d-flex justify-content-center">
				<Link to={`/help-center/${post.acf.category}/${post.slug}`}>
					<CategoryCardWrapper>
						<img
							src={icon}
							className="img-fluid category-image"
							alt={title + ' иконка'}
							style={{
								width: title === 'MAX' ? 160 : undefined
							}}
						/>
						<h5>
							<span className="faded-text">{fadedTitle}</span>
						</h5>
						<h3>{title}</h3>
					</CategoryCardWrapper>
				</Link>
			</div>
		);

	return (
		<div className="row d-flex justify-content-center align-items-center text-center w-100 my-5">
			<div className="col-12 col-xl-10">
				<h3 className="my-4">
					<span className="text-purple">Категории</span>
				</h3>
				<div className="row w-100 d-flex justify-content-center">
					{constants.categories.map((categoryProps, index) => {
						const p = posts.edges.find(
							(post) => post.node.acf.category === categoryProps.category
						);

						return (
							<CategoryCard
								key={index}
								{...categoryProps}
								post={p && p.node}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
};

