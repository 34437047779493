import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { CategoryWrapper } from '../shared/components';

export const MostPopularSection = () => {
  const data = useStaticQuery(graphql`
  query HelpCenter {
    internetFAQ: allWordpressWpHelp(filter: {acf: {category: {eq: "internet"}}}, limit: 5) {
      edges {
        node {
          title
          slug
          acf {
            category
          }
        }
      }
    }
    tvFAQ: allWordpressWpHelp(filter: {acf: {category: {eq: "tv"}}}, limit: 5) {
      edges {
        node {
          title
          slug
          acf {
            category
          }
        }
      }
    }
    clientsFAQ: allWordpressWpHelp(filter: {acf: {category: {eq: "clients"}}}, limit: 5) {
      edges {
        node {
          title
          slug
          acf {
            category
          }
        }
      }
    }
  }
  `);
  const tv = data.tvFAQ.edges;
  const clients = data.clientsFAQ.edges;
  const internet = data.internetFAQ.edges;
  const categories = [
    {
      title: 'Телевизия',
      list: tv
    },
    {
      title: 'Интернет',
      list: internet
    },
    {
      title: 'Клиенти',
      list: clients
    },
  ];
  const CategoryListCard = ({ title, list }) => (<CategoryWrapper className='my-2'>
    <h3>{title}</h3>

    <ul className='category-cards text-left'>
      {list.map(({ node }, index) => (
        <li key={index} className='my-1 row'>
          <div className='col-1 m-0 p-0'>
            <span className='icon category-arrow'></span>
          </div>
          <div className='col-10 m-0 p-0'>
            <Link to={`/help-center/${node.acf.category}/${node.slug}`}>{node.title}</Link>
          </div>
        </li>))}
    </ul>

    {list && list.length &&
      <Link to={`/help-center/${list[0].node.acf.category}/${list[0].node.slug}`}><span className='icon arrow-category-open'></span></Link>
    }
  </CategoryWrapper>);
  return (<div className='row d-flex justify-content-center align-items-center text-center w-100 my-5'>
    <div className='col-12 col-lg-11 col-xl-10'>
      <h3 className='my-4'><span className='text-purple'>Най-популярни въпроси</span></h3>
      <div className='row w-100 d-flex justify-content-center'>
        {categories.map(({ title, list }, index) => (<div className='col-12 col-md-6 col-lg-4 d-flex justify-content-center' key={index}>
          <CategoryListCard title={title} list={list} />
        </div>))}
      </div>
    </div>
  </div>);
};
